export const UserFragment = `
id
username
created_at
updated_at
last_activity_at
status
profile {
  id
  profile {
    name
    id
    description
  }
  groups
  role
  updated_at 
  created_at
}
attributes {
  email
  username
  phone_number
  address
  birthdate
  name
  middle_name
  family_name
  gender
  given_name
  locale
  groups
  nickname
  picture
  preferred_username
  timezone
  updated_at
  website
  country
  accountId
  contactId
  accountOwner
  accountName
  userActiveState
}
`;
